import { Pipe, PipeTransform } from '@angular/core';
import { KeyValStoreService } from '@app/_services/key-val-store.service';

@Pipe({
  name: 'keyValStore'
})
export class KeyValStorePipe implements PipeTransform {
  constructor(private keyValStore: KeyValStoreService) {}
  transform(value: any, ...args: any[]): any {
    const [defaultVal] = args;
    return this.keyValStore.get(value, defaultVal);
  }
}
