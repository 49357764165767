import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tgSuffix],[tg-suffix]',
})
export class TgSuffixDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.addClasses();
  }
  addClasses() {
    const classKey = 'tg-input-suffix';
    if (!this.el.nativeElement.classList.contains(classKey)) {
      this.el.nativeElement.classList.add(classKey);
    }
  }
}
