import * as i0 from '@angular/core';
import { inject, ChangeDetectorRef, NgZone, ViewContainerRef, Injector, isSignal, Directive, Input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { coerceAllFactory } from '@rx-angular/cdk/coercing';
import { createTemplateNotifier } from '@rx-angular/cdk/notifications';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { RxBaseTemplateNames, createTemplateManager } from '@rx-angular/cdk/template';
import { Subscription, ReplaySubject, Subject, merge, NEVER } from 'rxjs';
import { mergeAll, map, filter } from 'rxjs/operators';
const RxIfTemplateNames = {
  ...RxBaseTemplateNames,
  then: 'rxThen',
  else: 'rxElse'
};

/**
 * @Directive IfDirective
 * @description
 *
 * The `RxIf` directive is drop-in replacement for the `NgIf` directive, but with additional features.
 * `RxIf` allows you to bind observables directly without having the need of using the `async`
 * pipe in addition.
 *
 * This enables `rxIf` to completely operate on its own without having to interact with `NgZone`
 * or triggering global change detection.
 *
 * Read more about the RxIf directive in the [official
 *   docs](https://www.rx-angular.io/docs/template/api/rx-if-directive).
 *
 * @example
 * <app-item *rxIf="show$"></app-item>
 *
 * @docsCategory RxIf
 * @docsPage RxIf
 * @publicApi
 */
class RxIf {
  templateRef;
  /** @internal */
  strategyProvider = inject(RxStrategyProvider);
  /** @internal */
  cdRef = inject(ChangeDetectorRef);
  /** @internal */
  ngZone = inject(NgZone);
  /** @internal */
  viewContainerRef = inject(ViewContainerRef);
  /** @internal */
  injector = inject(Injector);
  /** @internal */
  subscription = new Subscription();
  /** @internal */
  _renderObserver;
  /** @internal */
  templateManager;
  /**
   * @description
   * The Observable or value to representing the condition.
   *
   * @example
   * showHero = true;
   * showHero$ = new BehaviorSubject<boolean>(true);
   *
   * <ng-container *rxIf="showHero">
   *   <app-hero></app-hero>
   * </ng-container>
   *
   * <ng-container *rxIf="showHero$">
   *   <app-hero></app-hero>
   * </ng-container>
   *
   * @param { ObservableInput<T> | T } rxIf
   */
  rxIf;
  /**
   * @description
   *
   * You can change the used `RenderStrategy` by using the `strategy` input of the `*rxIf`. It accepts
   * an `Observable<RxStrategyNames>` or
   *   [`RxStrategyNames`](https://github.com/rx-angular/rx-angular/blob/b0630f69017cc1871d093e976006066d5f2005b9/libs/cdk/render-strategies/src/lib/model.ts#L52).
   *
   * The default value for strategy is
   * [`normal`](https://www.rx-angular.io/docs/template/cdk/render-strategies/strategies/concurrent-strategies).
   *
   * Read more about this in the
   * [official docs](https://www.rx-angular.io/docs/template/api/rx-if-directive#use-render-strategies-strategy).
   *
   * @example
   *
   * \@Component({
   *   selector: 'app-root',
   *   template: `
   *     <ng-container *rxIf="showHero$; strategy: 'userBlocking'">
   *       <app-hero></app-hero>
   *     </ng-container>
   *
   *     <ng-container *rxIf="showHero$; strategy: strategy$">
   *       <app-hero></app-hero>
   *     </ng-container>
   *   `
   * })
   * export class AppComponent {
   *   strategy$ = of('immediate');
   * }
   *
   * @param { string | Observable<string> | undefined } strategyName
   * @see {@link RxStrategyNames}
   */
  set strategy(strategyName) {
    this.strategyHandler.next(strategyName);
  }
  /**
   * @description
   * Defines the template to be used when the bound value is falsy
   *
   * @example
   * <app-hero *rxIf="show$; else: noHero"></app-hero>
   * <ng-template #noHero><no-hero></no-hero></ng-template>
   */
  else;
  /**
   * @description
   * Defines the template to be used when the bound value is truthy
   *
   * @example
   * <ng-container *rxIf="show$; then: hero"></ng-container>
   * <ng-template #hero><app-hero></app-hero></ng-template>
   */
  then;
  /**
   * @description
   * Defines the template for the suspense state. Will be
   * shown when the bound Observable is in "suspense" state.
   * Suspense state is active when the current value is undefined or no value
   * was ever emitted.
   *
   * Read more about the reactive context in the
   * [official docs](https://www.rx-angular.io/docs/template/concepts/reactive-context).
   *
   * @example
   * <app-hero *rxIf="show$; suspense: suspenseTemplate" ></app-hero>
   * <ng-template #suspenseTemplate>
   *   <mat-progress-spinner></mat-progress-spinner>
   * </ng-template>
   *
   * @param { TemplateRef<RxIfViewContext> } suspense
   */
  suspense;
  /**
   * @description
   * Defines the template for the complete state. Will be
   * shown when the bound Observable is in "complete" state.
   *
   * Read more about the reactive context in the
   * [official docs](https://www.rx-angular.io/docs/template/concepts/reactive-context).
   *
   * @example
   * <app-hero *rxIf="show$; complete: completeTemplate" ></app-hero>
   * <ng-template #completeTemplate>
   *   <icon>thumbs_up</icon>
   * </ng-template>
   *
   * @param { TemplateRef<RxIfViewContext> } suspense
   */
  complete;
  /**
   * @description
   * Defines the template for the error state. Will be
   * shown when the bound Observable is in "error" state.
   *
   * Read more about the reactive context in the
   * [official docs](https://www.rx-angular.io/docs/template/concepts/reactive-context).
   *
   * @example
   * <app-hero *rxIf="show$; error: errorTemplate" ></app-hero>
   * <ng-template #errorTemplate>
   *   <icon>error</icon>
   * </ng-template>
   *
   * @param { TemplateRef<RxIfViewContext> } suspense
   */
  error;
  /**
   * @description
   * A trigger to manually set the active template. It accepts a `RxNotificationKind`
   * which determines what template to display. If no template is given, a context
   * variable resembling the notification state is put into the `Next`
   * template of the directive
   *
   * @example
   * <ng-container
   *  *rxIf="
   *    show$;
   *    let e = error;
   *    contextTrigger: contextTrigger$
   * ">
   *
   *   <app-hero></app-hero>
   *   <error *ngIf="e"></error>
   * </ng-container>
   *
   * // trigger template from component.ts
   * contextTrigger$.next(RxNotificationKind.error)
   *
   * @param { Observable<RxNotificationKind> } contextTrigger
   * @see {@link RxNotificationKind}
   */
  contextTrigger;
  /**
   * @description
   * A trigger to manually activate the default template. It accepts any value,
   * on emission it will switch to the let directives default template.
   *
   * @example
   * <ng-container
   *  *rxIf="
   *    show$;
   *    suspense: suspense
   *    nextTrigger: nextTrigger$
   * ">
   *
   *   <app-hero></app-hero>
   * </ng-container>
   *
   * <ng-template #suspense><loader></loader></ng-template>
   *
   * // trigger template from component.ts
   * nextTrigger$.next()
   *
   * @param { Observable<unknown> } nextTrigger
   */
  nextTrigger;
  /**
   * @description
   * A trigger to manually activate the suspense template. It accepts any value,
   * on emission it will display the suspense template. If no template is given,
   * the suspense context variable will be set to true instead.
   *
   * @example
   * <ng-container
   *  *rxIf="
   *    show$;
   *    let s = suspense;
   *    suspenseTrigger: suspenseTrigger$
   * ">
   *
   *   <app-hero></app-hero>
   *   <loader *ngIf="s"></loader>
   * </ng-container>
   *
   *
   * // trigger template from component.ts
   * suspenseTrigger$.next()
   *
   * @param { Observable<unknown> } suspenseTrigger
   */
  suspenseTrigger;
  /**
   * @description
   * A trigger to manually activate the error template. It accepts any value,
   * on emission it will display the error template. If no template is given,
   * the error context variable will be set to true instead.
   *
   * @example
   * <ng-container
   *  *rxIf="
   *    show$;
   *    let e = error;
   *    errorTrigger: errorTrigger$
   * ">
   *
   *   <app-hero></app-hero>
   *   <error *ngIf="e"></error>
   * </ng-container>
   *
   * // trigger template from component.ts
   * errorTrigger$.next()
   *
   * @param { Observable<unknown> } errorTrigger
   */
  errorTrigger;
  /**
   * @description
   * A trigger to manually activate the complete template. It accepts any value,
   * on emission it will display the error template. If no template is given,
   * the complete context variable will complete set to true instead.
   *
   * @example
   * <ng-container
   *  *rxIf="
   *    show$;
   *    let c = complete;
   *    completeTrigger: completeTrigger$
   * ">
   *
   *   <app-hero></app-hero>
   *   <done *ngIf="c"></done>
   * </ng-container>
   *
   * // trigger template from component.ts
   * completeTrigger$.next()
   *
   * @param { Observable<unknown> } completeTrigger
   */
  completeTrigger;
  /**
   * @description
   *
   * Structural directives maintain `EmbeddedView`s within a components' template.
   * Depending on the bound value as well as the configured `RxRenderStrategy`,
   * updates processed by the `*rxIf` directive might be asynchronous.
   *
   * Whenever a template gets inserted into, or removed from, its parent component, the directive has to inform the
   *   parent in order to update any view- or contentquery (`@ViewChild`, `@ViewChildren`, `@ContentChild`,
   *   `@ContentChildren`).
   *
   * Read more about this in the
   * [official
   *   docs](https://www.rx-angular.io/docs/template/api/rx-if-directive#local-strategies-and-view-content-queries-parent).
   *
   * @example
   * \@Component({
   *   selector: 'app-root',
   *   template: `
   *    <app-component>
   *      <app-item
   *        *rxIf="
   *          show$;
   *          parent: true;
   *        "
   *      >
   *      </app-item>
   *    </app-component>
   *   `
   * })
   * export class AppComponent {
   *   show$ = state.select('showItem');
   * }
   *
   * @param {boolean} renderParent
   *
   * @deprecated this flag will be dropped soon, as it is no longer required when using signal based view & content queries
   */
  renderParent = this.strategyProvider.config.parent;
  /**
   * @description
   * A flag to control whether `*rxIf` templates are created within `NgZone` or not.
   * The default value is `true, `*rxIf` will create its `EmbeddedView` inside `NgZone`.
   *
   * Event listeners normally trigger zone.
   * Especially high frequency events can cause performance issues.
   *
   * Read more about this in the
   * [official docs](https://www.rx-angular.io/docs/template/api/let-directive#working-with-event-listeners-patchzone).
   *
   * @example
   * \@Component({
   *   selector: 'app-root',
   *   template: `
   *    <app-component>
   *      <app-item
   *        *rxIf="
   *          show$;
   *          patchZone: false;
   *        "
   *        (drag)="itemDrag($event)"
   *      >
   *      </app-item>
   *    </app-component>
   *   `
   * })
   * export class AppComponent {
   *   show$ = state.select('showItem');
   * }
   *
   * @param {boolean} patchZone
   */
  patchZone = this.strategyProvider.config.patchZone;
  /**
   * @description
   * A `Subject` which emits whenever `*rxIf` rendered a change to the view.
   * This enables developers to perform actions when rendering has been done.
   * The `renderCallback` is useful in situations where you
   * rely on specific DOM properties like the dimensions of an item after it got rendered.
   *
   * The `renderCallback` emits the latest value causing the view to update.
   *
   * @example
   * \@Component({
   *   selector: 'app-root',
   *   template: `
   *    <app-component>
   *      <app-item
   *        *rxIf="
   *          show$;
   *          renderCallback: rendered;
   *        "
   *      >
   *      </app-item>
   *    </app-component>
   *   `
   * })
   * export class AppComponent {
   *  show$ = state.select('showItem');
   *  // this emits whenever rxIf finished rendering changes
   *  rendered = new Subject<boolean>();
   *
   *   constructor(elementRef: ElementRef<HTMLElement>) {
   *     rendered.subscribe(() => {
   *       // item is rendered, we can access its dom now
   *     })
   *   }
   * }
   *
   * @param {Subject<boolean>} callback
   */
  set renderCallback(callback) {
    this._renderObserver = callback;
  }
  /** @internal */
  triggerHandler = new ReplaySubject(1);
  /** @internal */
  templateNotifier = createTemplateNotifier();
  /** @internal */
  strategyHandler = coerceAllFactory(() => new ReplaySubject(1), mergeAll());
  /** @internal */
  rendered$ = new Subject();
  /** @internal */
  get thenTemplate() {
    return this.then ? this.then : this.templateRef;
  }
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  /** @internal */
  ngOnInit() {
    this.subscription.add(merge(this.contextTrigger || NEVER, this.nextTrigger?.pipe(map(() => "next" /* RxNotificationKind.Next */)) || NEVER, this.suspenseTrigger?.pipe(map(() => "suspense" /* RxNotificationKind.Suspense */)) || NEVER, this.completeTrigger?.pipe(map(() => "complete" /* RxNotificationKind.Complete */)) || NEVER, this.errorTrigger?.pipe(map(() => "error" /* RxNotificationKind.Error */)) || NEVER).pipe(filter(v => !!v)).subscribe(t => this.triggerHandler.next(t)));
    this.subscription.add(this.templateManager.render(this.templateNotifier.values$).subscribe(n => {
      this.rendered$.next(n);
      this._renderObserver?.next(n);
    }));
  }
  /** @internal */
  ngOnChanges(changes) {
    if (!this.templateManager) {
      this._createTemplateManager();
    }
    if (changes.then && !changes.then.firstChange) {
      this.templateManager.addTemplateRef(RxIfTemplateNames.then, this.thenTemplate);
    }
    if (changes.else) {
      this.templateManager.addTemplateRef(RxIfTemplateNames.else, this.else);
    }
    if (changes.complete) {
      this.templateManager.addTemplateRef(RxIfTemplateNames.complete, this.complete);
    }
    if (changes.suspense) {
      this.templateManager.addTemplateRef(RxIfTemplateNames.suspense, this.suspense);
      this.templateNotifier.withInitialSuspense(!!this.suspense);
    }
    if (changes.error) {
      this.templateManager.addTemplateRef(RxIfTemplateNames.error, this.error);
    }
    if (changes.rxIf) {
      if (isSignal(this.rxIf)) {
        this.templateNotifier.next(toObservable(this.rxIf, {
          injector: this.injector
        }));
      } else {
        this.templateNotifier.next(this.rxIf);
      }
    }
  }
  /** @internal */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /** @internal */
  _createTemplateManager() {
    const getNextTemplate = value => {
      return value ? RxIfTemplateNames.then : this.else ? RxIfTemplateNames.else : undefined;
    };
    this.templateManager = createTemplateManager({
      templateSettings: {
        viewContainerRef: this.viewContainerRef,
        customContext: rxIf => ({
          rxIf
        })
      },
      renderSettings: {
        cdRef: this.cdRef,
        parent: coerceBooleanProperty(this.renderParent),
        patchZone: this.patchZone ? this.ngZone : false,
        defaultStrategyName: this.strategyProvider.primaryStrategy,
        strategies: this.strategyProvider.strategies
      },
      notificationToTemplateName: {
        ["suspense" /* RxNotificationKind.Suspense */]: value => this.suspense ? RxIfTemplateNames.suspense : getNextTemplate(value),
        ["next" /* RxNotificationKind.Next */]: value => getNextTemplate(value),
        ["error" /* RxNotificationKind.Error */]: value => this.error ? RxIfTemplateNames.error : getNextTemplate(value),
        ["complete" /* RxNotificationKind.Complete */]: value => this.complete ? RxIfTemplateNames.complete : getNextTemplate(value)
      },
      templateTrigger$: this.triggerHandler
    });
    this.templateManager.addTemplateRef(RxIfTemplateNames.then, this.thenTemplate);
    this.templateManager.nextStrategy(this.strategyHandler.values$);
  }
  /** @internal */
  static rxIfUseIfTypeGuard;
  /**
   * Assert the correct type of the expression bound to the `ngIf` input within the template.
   *
   * The presence of this static field is a signal to the Ivy template type check compiler that
   * when the `NgIf` structural directive renders its template, the type of the expression bound
   * to `ngIf` should be narrowed in some way. For `NgIf`, the binding expression itself is used to
   * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `NgIf`.
   */
  static ngTemplateGuard_rxIf;
  /**
   * Asserts the correct type of the context for the template that `NgIf` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgIf` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard(dir, ctx) {
    return true;
  }
  /** @nocollapse */
  static ɵfac = function RxIf_Factory(t) {
    return new (t || RxIf)(i0.ɵɵdirectiveInject(i0.TemplateRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: RxIf,
    selectors: [["", "rxIf", ""]],
    inputs: {
      rxIf: "rxIf",
      strategy: [i0.ɵɵInputFlags.None, "rxIfStrategy", "strategy"],
      else: [i0.ɵɵInputFlags.None, "rxIfElse", "else"],
      then: [i0.ɵɵInputFlags.None, "rxIfThen", "then"],
      suspense: [i0.ɵɵInputFlags.None, "rxIfSuspense", "suspense"],
      complete: [i0.ɵɵInputFlags.None, "rxIfComplete", "complete"],
      error: [i0.ɵɵInputFlags.None, "rxIfError", "error"],
      contextTrigger: [i0.ɵɵInputFlags.None, "rxIfContextTrigger", "contextTrigger"],
      nextTrigger: [i0.ɵɵInputFlags.None, "rxIfNextTrigger", "nextTrigger"],
      suspenseTrigger: [i0.ɵɵInputFlags.None, "rxIfSuspenseTrigger", "suspenseTrigger"],
      errorTrigger: [i0.ɵɵInputFlags.None, "rxIfErrorTrigger", "errorTrigger"],
      completeTrigger: [i0.ɵɵInputFlags.None, "rxIfCompleteTrigger", "completeTrigger"],
      renderParent: [i0.ɵɵInputFlags.None, "rxIfParent", "renderParent"],
      patchZone: [i0.ɵɵInputFlags.None, "rxIfPatchZone", "patchZone"],
      renderCallback: [i0.ɵɵInputFlags.None, "rxIfRenderCallback", "renderCallback"]
    },
    standalone: true,
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RxIf, [{
    type: Directive,
    args: [{
      selector: '[rxIf]',
      standalone: true
    }]
  }], () => [{
    type: i0.TemplateRef
  }], {
    rxIf: [{
      type: Input
    }],
    strategy: [{
      type: Input,
      args: ['rxIfStrategy']
    }],
    else: [{
      type: Input,
      args: ['rxIfElse']
    }],
    then: [{
      type: Input,
      args: ['rxIfThen']
    }],
    suspense: [{
      type: Input,
      args: ['rxIfSuspense']
    }],
    complete: [{
      type: Input,
      args: ['rxIfComplete']
    }],
    error: [{
      type: Input,
      args: ['rxIfError']
    }],
    contextTrigger: [{
      type: Input,
      args: ['rxIfContextTrigger']
    }],
    nextTrigger: [{
      type: Input,
      args: ['rxIfNextTrigger']
    }],
    suspenseTrigger: [{
      type: Input,
      args: ['rxIfSuspenseTrigger']
    }],
    errorTrigger: [{
      type: Input,
      args: ['rxIfErrorTrigger']
    }],
    completeTrigger: [{
      type: Input,
      args: ['rxIfCompleteTrigger']
    }],
    renderParent: [{
      type: Input,
      args: ['rxIfParent']
    }],
    patchZone: [{
      type: Input,
      args: ['rxIfPatchZone']
    }],
    renderCallback: [{
      type: Input,
      args: ['rxIfRenderCallback']
    }]
  });
})();
/**
 * @internal
 * @description
 * Coerces a data-bound value (typically a string) to a boolean.
 *
 */
function coerceBooleanProperty(value) {
  return value != null && `${value}` !== 'false';
}

/**
 * Generated bundle index. Do not edit.
 */

export { RxIf, RxIfTemplateNames };
